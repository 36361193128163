<template>
  <div>
    <vx-card :title="title">
      <div class="vx-col w-full">
        <vs-button
          class="mt-2"
          type="border"
          color="danger"
          v-on:click="handleBack"
          >Back</vs-button
        >
      </div>
      <div class="vx-row mt-4 mx-2">
        <table class="table">
          <tbody>
            <tr>
              <td scope="row">UUID</td>
              <td>:</td>
              <td>{{ data.uuid }}</td>
            </tr>
            <tr>
              <td scope="row">Payment Method</td>
              <td>:</td>
              <td>{{ data.payment_method_name | checkName }}</td>
            </tr>
            <tr>
              <td scope="row">Amount</td>
              <td>:</td>
              <td>{{ data.total }}</td>
            </tr>
            <tr>
              <td scope="row">Date</td>
              <td>:</td>
              <td>{{ data.date | formatDate }}</td>
            </tr>
            <tr>
              <td scope="row">Salesman</td>
              <td>:</td>
              <td>{{ data.personal_name }}</td>
            </tr>
            <tr>
              <td scope="row">Customer Info</td>
              <td>:</td>
              <td>{{ data.customer_info }}</td>
            </tr>
            <tr>
              <td scope="row">Ref No.</td>
              <td>:</td>
              <td>{{ data.reference_number }}</td>
            </tr>
            <tr>
              <td scope="row">Description</td>
              <td>:</td>
              <td>{{ data.description }}</td>
            </tr>
            <tr>
              <td scope="row">Image Name</td>
              <td>:</td>
              <td>
                <a :href="data.image_url" target="_blank">{{
                  data.image_name
                }}</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- data items table -->
      <div class="my-2">
        <h5 class="my-2">Data items</h5>
        <vs-table
          stripe
          border
          description
          :total="table.data.length"
          :data="table.data"
        >
          <template slot="thead">
            <vs-th>SKU</vs-th>
            <vs-th>Unit</vs-th>
            <vs-th>Qty</vs-th>
            <vs-th>Total</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td>{{ tr.sku_code }}</vs-td>
              <vs-td>{{ tr.unit_name }}</vs-td>
              <vs-td>{{ tr.quantity }}</vs-td>
              <vs-td>{{ tr.total }}</vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </vx-card>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    const id = this.$route.params.id;

    return {
      title: "Detail Customer Order Payment",
      baseUrl: "/api/sfa/v1/payment/customer-order-payment/" + id,
      detailShow: "core vx-col md:w-1/2 w-full mb-base ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,
      deleteId: null,
      table: this.tableDefaultState(),
      data: null,
      id: id,
    };
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
      };
    },
    getData() {
      this.$vs.loading();
      this.$http.get(this.baseUrl).then((resp) => {
        if (resp.status == "OK") {
          this.data = resp.data;
          this.table.data = resp.data.items;
          this.$vs.loading.close();
        }
      });
    },
    handleBack() {
      this.$router.push({ name: "payment" });
    },
    checkName(val) {
      if (val == "COD (Cash On Delivery)") {
        return "Cash";
      }
      return val;
    },
  },
  filters: {
    formatDate(value) {
      if (value) {
        let res = moment().format("DD MMMM YYYY");
        return res;
      }
    },
    formatStatus(value) {
      if (value == 1) {
        return "Open";
      } else {
        return "Close";
      }
    },
  },
  watch: {},
  mounted() {
    this.getData();
  },
};
</script>

<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
</style>
